import { REQUEST_USER, LOAD_USER, SUCESS_USER, LOGOUT_USER } from "../constants/userConstants";

const userReducer = (state = { user: null }, action) => {
    switch (action.type) {
        case REQUEST_USER:
            return {
                ...state,
                user: null
            }

        case SUCESS_USER:
        case LOAD_USER:
            return {
                ...state,
                user: action.payload
            }

        case LOGOUT_USER:
            return {
                ...state,
                user: null
            }

        default:
            return state;
    }
}

export default userReducer;