import React, { useEffect, useState } from 'react'
import "./home.scss"
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from '../../components/sideBar/SideBar';
import Header from '../../components/header/Header';


const Home = () => {


    const navigate = useNavigate();
    const token = localStorage.getItem("accessToken");
    const { user } = useSelector(state => state.userReducer);



    /////////////////////////////// authentication ///////////////////////////////

    useEffect(() => {
        if (!token && !user) {
            navigate('/login');
        }
    }, [user, token, navigate]);



    //////////////////------------------ access Array ---------------//////////////////////////
    const [accessArray, setAccessArray] = useState([]);
    useEffect(() => {
        if (user) {
            setAccessArray(user.user_access);
        }
    }, [user]);



    ////////////////////--------------- Navigation ------------////////////////////////////////

    const userNavigation = (data) => {
        switch (data.user_type) {
            case "factory_operator":
                navigate("/operator");
                break;

            case "gatekeeper":
                navigate("/gatekeeper");
                break;

            case "packer":
                navigate("/packer");
                break;

            default:
                break;
        }
    }



    ///////////////////////////---------Side Bar --------///////////////////////////

    const openSideBar = () => {
        document.getElementById("sideBar").style.left = "0px";
    }





    return (
        <>
            <div className='main'>
                <div className='home'>

                    <SideBar />


                    {/* <div className='headBar'> <span onClick={openSideBar}><MenuIcon /></span></div> */}
                    <Header/>

                    <h2>User Access</h2>


                    {accessArray.map((val, index) => {
                        return (
                            <div className='btnDiv' key={index} onClick={() => userNavigation(val)}>
                                <Button className='bt' variant='contained'> {val.user_type} </Button>
                            </div>
                        );
                    })}


                </div>
            </div>
        </>
    )
}

export default Home