import { HISTORY_FAIL, HISTORY_SUCCESS } from "../constants/userConstants";

const operatorHistory = (state = { history: null }, action) => {
    switch (action.type) {
        case HISTORY_SUCCESS:
            return {
                ...state,
                history: action.payload
            }

            
        case HISTORY_FAIL : 
        return {
            ...state,
            history: null
        }

        default:
            return state;
    }
}

export default operatorHistory;