import React, { useEffect, useState } from 'react'
import "./qrHistory.scss"
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from '../../../components/sideBar/SideBar';
import { useDispatch, useSelector } from 'react-redux';
import { getQrHistory } from '../../../action/qrHistory';
import { Button } from '@mui/material';



const QrHistory = () => {



    ///////////////////////////---------Side Bar --------///////////////////////////
    const openSideBar = () => {
        document.getElementById("sideBar").style.left = "0px";
    }


    //////////////////////////////// redux ////////////////////////////////////
    const [Array, setArray] = useState([]);
    const dispatch = useDispatch();
    const { qrData } = useSelector(state => state.qrHistoryReducer);

    useEffect(() => {
        dispatch(getQrHistory());
    }, []);

    useEffect(() => {
        if (qrData) {
            setArray(qrData);
        }
    }, [qrData]);



    return (
        <>
            <div className='main'>
                <div className='qrHistory'>

                    <SideBar />

                    <div className='qrHeader'>
                        <Button className='btn2' variant='outlined' onClick={openSideBar}><MenuIcon fontSize='large' /></Button>
                    </div>

                    <div className='tableInner'>
                        <div className='tableCon'>
                            <div>Serial no.</div>
                            <div>Created on</div>
                            <div>PDF</div>
                        </div>

                        {Array.map((val, index) => {
                            return (
                                <div className='tableCon' key={index}>
                                    <div>{val.starting_serial_number}</div>
                                    <div>{String(val.created_on).slice(0, 10)}</div>
                                    <a href={val.pdf_link} target="_blank">view</a>
                                </div>
                            );
                        })}

                    </div>


                </div>
            </div>
        </>
    )
}

export default QrHistory