import { SUCCESS_QR, REQUEST_QR, RESET_QR } from "../constants/qrConstants";

const qrReducer = (state = { qrUrl: null, qrLoading: false }, action) => {
    switch (action.type) {

        case REQUEST_QR:
            return {
                ...state,
                qrLoading: true
            }

        case SUCCESS_QR:
            return {
                ...state,
                qrUrl: action.payload,
                qrLoading: false
            }

        case RESET_QR: {
            return {
                ...state,
                qrLoading: false,
                qrUrl: null
            }
        }

        default: return state;
    }
}

export default qrReducer;