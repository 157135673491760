import React, { useEffect, useRef, useState } from 'react'
import "./sideBar.scss"
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../action/userActions';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { HISTORY_FAIL } from '../../constants/userConstants';
import { Close, KeyboardArrowDown } from '@mui/icons-material';

const SideBar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch(logoutUser());
        dispatch({ type: HISTORY_FAIL })
    }

    const closeSideBar = () => {
        document.getElementById("sideBar").style.left = "-275px";
    }



    ///////////////////---------------- access control --------------//////////////////////
    const { user } = useSelector(state => state.userReducer);

    const [operator_access, set_Operator_access] = useState(false);
    const [gatekeeper_access, set_gatekeeper_access] = useState(false);
    const [packer_access, set_packer_access] = useState(false);


    useEffect(() => {
        if (user) {
            // // console.log(user);
            for (let i = 0; i < user.user_access.length; i++) {
                if (user.user_access[i].user_type == "factory_operator") {
                    set_Operator_access(true);
                }
                if (user.user_access[i].user_type == "gatekeeper") {
                    set_gatekeeper_access(true);
                }
                if (user.user_access[i].user_type == "packer") {
                    set_packer_access(true);
                }
            }
        }
    }, [user]);
    const [isOperatorDropdownOpen, setIsOperatorDropdownOpen] = useState(false);
    const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
    // const dropdownRef1 = useRef(null);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
    // const dropdownRef2 = useRef(null);
    const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
    // const dropdownRef3 = useRef(null);

    // useEffect(() => {
    //     // Close the dropdown when clicking outside of it
    //     function handleClickOutside1(event, value) {
    //         if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
    //             setIsDropdownOpen1(false);
    //         }

    //     }

    //     document.addEventListener('mousedown', handleClickOutside1);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside1);
    //     };
    // }, []);
    // useEffect(() => {
    //     // Close the dropdown when clicking outside of it
    //     function handleClickOutside2(event, value) {
    //         if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
    //             setIsDropdownOpen2(false);
    //         }

    //     }

    //     document.addEventListener('mousedown', handleClickOutside2);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside2);
    //     };
    // }, []);
    // useEffect(() => {
    //     // Close the dropdown when clicking outside of it
    //     function handleClickOutside3(event, value) {
    //         if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
    //             setIsDropdownOpen3(false);
    //         }

    //     }

    //     document.addEventListener('mousedown', handleClickOutside3);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside3);
    //     };
    // }, []);
    const handleListItemClick1 = (value) => {

        setIsDropdownOpen1(false);
    };
    const [showGate, setShowGate] = useState(false)

    const openGate = () => {
        setShowGate(!showGate)
    }


    return (
        <>
            <div id='sideBar'>

                {/* <Button className='closeBar' variant='outlined' onClick={closeSideBar}><ClearIcon /></Button> */}

                <div className='special flex justify-between'>
                    <div onClick={() => navigate("/")} className='text-black'>Home</div>
                    <div className='mr-4 hover:text-black' onClick={closeSideBar}>
                        <Close/>
                    </div>
                </div>


                {operator_access && (
                    <div className='relative group cursor-pointer' onClick={() => setIsDropdownOpen1(!isDropdownOpen1)}>
                        <div className='group flex items-center text-sm mt-3 gap-3.5 font-medium p-3 hover:bg-[#DCDDE1] rounded-md text-black m-2'>
                            <h1 className=''>Factory Operator</h1>
                            <i
                                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:text-gray-600 cursor-pointer transition-transform duration-300 ${isDropdownOpen1 ? 'rotate-180' : '' // Add rotate-180 class when dropdown is open
                                    }`}
                            >
                                <KeyboardArrowDown />
                            </i>
                        </div>
                    </div>
                )}
                {isDropdownOpen1 && (
                    <div className='top-full w-full rounded-md overflow-hidden opacity-100 scale-100 transform origin-top transition-all duration-300 ease-out '>
                        <ul className=''>
                            <li
                                className='group flex items-center text-sm m-2 font-medium hover:bg-[#DCDDE1] rounded-md text-black py-2 px-6 cursor-pointer'
                                onClick={() => navigate("/operator")}
                            >
                                Create QR
                            </li>
                            <li
                                className='group flex items-center text-sm m-2 font-medium hover:bg-[#DCDDE1] rounded-md text-black py-2 px-6 cursor-pointer'
                                onClick={() => navigate("/generated_qr")}
                            >
                                Generated QR
                            </li>
                        </ul>
                    </div>
                )}
                {/* {gatekeeper_access && <section>
                    <label onClick={openGate}>Gatekeeper</label>
                    {showGate ? ( <div onClick={() => navigate("/gatekeeper")}>Dispatch</div>) : null}
                    
                </section>} */}
                   {gatekeeper_access && (
                    <div className='relative group cursor-pointer' onClick={() => setIsDropdownOpen2(!isDropdownOpen2)}>
                        <div className='group flex items-center text-sm mt-3 gap-3.5 font-medium p-3 hover:bg-[#DCDDE1] rounded-md text-black m-2'>
                            <h1>Gatekeeper</h1>
                            <i
                                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:text-gray-600 cursor-pointer transition-transform duration-300 ${isDropdownOpen2 ? 'rotate-180' : '' // Add rotate-180 class when dropdown is open
                                    }`}
                            >
                                <KeyboardArrowDown />
                            </i>
                        </div>
                    </div>
                )}
                {isDropdownOpen2 && (
                    <div className='top-full w-full rounded-md overflow-hidden opacity-100 scale-100 transform origin-top transition-all duration-300 ease-out '>
                        <ul className=''>
                            <li
                                className='group flex items-center text-sm m-2 font-medium hover:bg-[#DCDDE1] rounded-md text-black py-2 px-6 cursor-pointer'
                                onClick={() => navigate("/gatekeeper")}
                            >
                               Dispatch
                            </li>
                          
                        </ul>
                    </div>
                )}

                {/* {packer_access && <section>
                    <label>Packer</label>
                    <div onClick={() => navigate("/packer")}>Link QR</div>
                </section>} */}
                {packer_access && (
                    <div className='relative group cursor-pointer'  onClick={() => setIsDropdownOpen3(!isDropdownOpen3)}>
                        <div className='group flex items-center text-sm mt-3 gap-3.5 font-medium p-3 hover:bg-[#DCDDE1] rounded-md text-black m-2'>
                            <h1>Packer</h1>
                            <i
                                className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:text-gray-600 cursor-pointer transition-transform duration-300 ${isDropdownOpen3 ? 'rotate-180' : '' // Add rotate-180 class when dropdown is open
                                    }`}
                            >
                                <KeyboardArrowDown />
                            </i>
                        </div>
                    </div>
                )}
                {isDropdownOpen3 && (
                    <div className='top-full w-full rounded-md overflow-hidden opacity-100 scale-100 transform origin-top transition-all duration-300 ease-out '>
                        <ul className=''>
                            <li
                                className='group flex items-center text-sm m-2 font-medium hover:bg-[#DCDDE1] rounded-md text-black py-2 px-6 cursor-pointer'
                                onClick={() => navigate("/packer")}
                            >
                                Link QR
                            </li>
                          
                        </ul>
                    </div>
                )}
                

                {/* <div className='special'>
                    <div onClick={() => { logout(); navigate("/login") }}>Logout</div>
                </div> */}

            </div>
        </>
    )
}

export default SideBar