import { SUCCESS_QR_HISTORY } from "../constants/qrConstants";
import axios from "../api/api";

export const getQrHistory = () => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
        const { data } = await axios.post("/factory_operator_QR_history", { token: token }, config);
        dispatch({ type: SUCCESS_QR_HISTORY, payload: data });
    } catch (error) {
        // console.log(error);
    }
}