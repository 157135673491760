import { combineReducers } from "redux";
import productReducer from "./productReducer";
import qrReducer from "./qrReducer";
import userReducer from "./userReducer";
import operatorHistory from "./operatorHistory";
import qrHistoryReducer from "./qrHistoryReducer";

const rootReducer = combineReducers({
  userReducer: userReducer,
  productReducer: productReducer,
  qrReducer: qrReducer,
  operatorHistory: operatorHistory,
  qrHistoryReducer:qrHistoryReducer
});

export default rootReducer;