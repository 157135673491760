import React, { useEffect, useState } from 'react'
import "../home/home.scss"
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from '../../components/sideBar/SideBar';
import Header from '../../components/header/Header';


const Profile = () => {


    const navigate = useNavigate();
    const token = localStorage.getItem("accessToken");
    const { user } = useSelector(state => state.userReducer);



    /////////////////////////////// authentication ///////////////////////////////

    useEffect(() => {
        if (!token && !user) {
            navigate('/login');
        }
    }, [user, token, navigate]);



    //////////////////------------------ access Array ---------------//////////////////////////
    const [accessArray, setAccessArray] = useState([]);
    useEffect(() => {
        if (user) {
            setAccessArray(user.user_access);
        }
    }, [user]);



    ////////////////////--------------- Navigation ------------////////////////////////////////

    const userNavigation = (data) => {
        switch (data.user_type) {
            case "factory_operator":
                navigate("/operator");
                break;

            case "gatekeeper":
                navigate("/gatekeeper");
                break;

            case "packer":
                navigate("/packer");
                break;

            default:
                break;
        }
    }



    ///////////////////////////---------Side Bar --------///////////////////////////

    const openSideBar = () => {
        document.getElementById("sideBar").style.left = "0px";
    }


    const [activeTab, setActiveTab] = useState('profile'); // Initialize active tab as 'profile'

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };


    return (
        <>
            <div className='main'>
                <div className='home'>

                    <SideBar />


                    {/* <div className='headBar'> <span onClick={openSideBar}><MenuIcon /></span></div> */}
                    <Header />

                    <div>

                        <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                            <ul class="flex flex-wrap -mb-px">
                                <li class="mr-2">
                                    <Link to="/profile"
                                        onClick={() => handleTabClick('profile')}
                                        className={`inline-block p-4 rounded-t-lg ${activeTab === 'profile'
                                            ? 'text-blue-600 border-b-2 border-blue-600 active dark:text-blue-600 dark:border-blue-600'
                                            : 'hover:text-blue-600 text-[#6B7280]'
                                            }`}

                                    >
                                        Profile
                                    </Link>
                                </li>

                                <li class="mr-2">
                                    <Link to="/password_update"
                                        onClick={() => handleTabClick('password')}
                                        className={`inline-block p-4 rounded-t-lg ${activeTab === 'password'
                                            ? 'text-blue-600 border-b-2 border-blue-600 active dark:text-blue-600 dark:border-blue-600'
                                            : 'hover:text-blue-600 text-[#6B7280]'
                                            }`}

                                    >
                                        Password
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='mt-7 flex  flex-col mx-4'>
                        <div className='w-1/3'>
                            <label className="text-[16px] leading-[21px] font-semibold text-[#6B7280]">Email</label>
                        </div>
                        {/* <div className='w-2/3'>
                                <input type="password" className="form-control w-full border border-[#D1D5DB] rounded-md p-2 h-11 input" name='newPassword'

                                    value={userData.newPassword} placeholder="Enter new passsword" onChange={handleOnChange} />

                            </div> */}
                        <div className='w-full mt-3'>
                            <div className=' '>
                                <input type='text' placeholder='Enter new password' className='h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input' name='newPassword'
                                    value={user?.email}
                                    readOnly
                                // onChange={handleOnChange}
                                />
                                
                            </div>

                        </div>
                    </div>
                    
                    <div className='mt-7 flex  flex-col mx-4'>
                        <div className='w-1/3'>
                            <label className="text-[16px] leading-[21px] font-semibold text-[#6B7280]">Company Name</label>
                        </div>
                        {/* <div className='w-2/3'>
                                <input type="password" className="form-control w-full border border-[#D1D5DB] rounded-md p-2 h-11 input" name='newPassword'

                                    value={userData.newPassword} placeholder="Enter new passsword" onChange={handleOnChange} />

                            </div> */}
                        <div className='w-full mt-3'>
                            <div className=' '>
                                <input type='text' placeholder='Enter new password' className='h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input' name='newPassword'
                                    value={user?.company_name}
                                    readOnly
                                // onChange={handleOnChange}
                                />
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Profile