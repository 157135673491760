import React, { useEffect, useState } from 'react'
import "../home/home.scss"

import '../../../src/index.css'
import { useSelector } from 'react-redux';
import { Button, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SideBar from '../../components/sideBar/SideBar';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Header from '../../components/header/Header';
import { toast } from 'react-toastify';
import axios from '../../api/api'
import { useForm } from 'react-hook-form';


const Password = () => {


    const navigate = useNavigate();
    const token = localStorage.getItem("accessToken");
    const { user } = useSelector(state => state.userReducer);



    /////////////////////////////// authentication ///////////////////////////////

    useEffect(() => {
        if (!token && !user) {
            navigate('/login');
        }
    }, [user, token, navigate]);



    //////////////////------------------ access Array ---------------//////////////////////////
    const [accessArray, setAccessArray] = useState([]);
    useEffect(() => {
        if (user) {
            setAccessArray(user.user_access);
        }
    }, [user]);



    ////////////////////--------------- Navigation ------------////////////////////////////////

    const userNavigation = (data) => {
        switch (data.user_type) {
            case "factory_operator":
                navigate("/operator");
                break;

            case "gatekeeper":
                navigate("/gatekeeper");
                break;

            case "packer":
                navigate("/packer");
                break;

            default:
                break;
        }
    }



    ///////////////////////////---------Side Bar --------///////////////////////////

    const openSideBar = () => {
        document.getElementById("sideBar").style.left = "0px";
    }


    const [activeTab, setActiveTab] = useState('password'); // Initialize active tab as 'profile'

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [show, setShow] = useState(false);
    const [animation, setAnimation] = useState(false);

    const [userData, setUserData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""

    });
 
    const handleOnChange = (e) => {
        const { name, value } = e.target;

        // Clear error messages based on the input field's name
        if (name === "currentPassword") {
            setCurrPassErr("");
        }
        if (name === "newPassword") {
            setNewPassErr("");
        }
        if (name === "confirmPassword") {  // Correct the field name here
            setConfPassErr("");
        }

        setUserData({ ...userData, [name]: value });
    }




    const closeAnimation = () => {
        setAnimation(false);
        setShow(false);
    }
    const [passwordMatch, setPasswordMatch] = useState("")


    const [showPass, setShowPass] = useState(false);
    const [showPassNew, setShowPassNew] = useState(false);
    const [showPassConf, setShowPassConf] = useState(false);

    const resetPasswordValue = () => {
        setUserData({
            currentPassword: "",
            newPassword: "",
            confirmPassword: ""
        });
    }
    // console.log(passwordMatch)

    const [currPassErr, setCurrPassErr] = useState("")
    const [newPassErr, setNewPassErr] = useState("")
    const [confPassErr, setConfPassErr] = useState("")
    const validateForm = () => {

        let isValid = true;
        if (userData.newPassword === userData.confirmPassword) {
            isValid = true
        }
        if  (userData.newPassword !== userData.confirmPassword) {
            toast.error("Password does not match")
            isValid = false
        }

        if (userData.currentPassword == "") {
            setCurrPassErr("Current Password is required")
            isValid = false
        }
        if (userData.newPassword == "") {
            setNewPassErr("New Password is required")
            isValid = false
        }
        if (userData.confirmPassword == "") {
            setConfPassErr("Confirm Password is required")
            isValid = false
        }
       
        // Add more validation checks for other fields as needed

        return isValid;
    };

    useEffect(() => {
        setPasswordMatch(userData.newPassword);
    }, [userData.newPassword]);
    const updatePassword = async () => {
        const isFormValid = validateForm();

        if (!isFormValid) {
            return;
        }

        try {

            const token = localStorage.getItem("accessToken")
            const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
            setLoading(true);
            const { data } = await axios.post(`/change-company-user-password`, { token: token, old_password: userData.currentPassword, new_password: passwordMatch }, config);

            setLoading(false)
            // navigate(`/login`)
            toast.success("Password updated successfully")
            resetPasswordValue()

        } catch (error) {
            // console.log(error);
            toast.error(error.response.data)

            setLoading(false)
            if (error.response.data === "Authentication failed") {
                navigate(`/login`)
            }
        }

    };



    return (
        <>
            <div className='main'>

                <div className='home'>

                    <SideBar />


                    {/* <div className='headBar'> <span onClick={openSideBar}><MenuIcon /></span></div> */}
                    
                    <Header />

                    {loading ? (
                        <div className="flex items-center justify-center w-full h-screen fixed inset-0 bg-gray-100 bg-opacity-75">
                            {/* CircularProgress spinner */}
                            <CircularProgress size={60} color="primary" />
                        </div>
                    ) : null}

                    <div>

                        <div class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                            <ul class="flex flex-wrap -mb-px">
                                <li class="mr-2">
                                    <Link to="/profile"
                                        onClick={() => handleTabClick('profile')}
                                        className={`inline-block p-4 rounded-t-lg ${activeTab === 'profile'
                                            ? 'text-blue-600 border-b-2 border-blue-600 active dark:text-blue-600 dark:border-blue-600'
                                            : 'hover:text-blue-600 text-[#6B7280]'
                                            }`}

                                    >
                                        Profile
                                    </Link>
                                </li>

                                <li class="mr-2">
                                    <Link to="/password_update"
                                        onClick={() => handleTabClick('password')}
                                        className={`inline-block p-4 rounded-t-lg ${activeTab === 'password'
                                            ? 'text-blue-600 border-b-2 border-blue-600 active dark:text-blue-600 dark:border-blue-600'
                                            : 'hover:text-blue-600 text-[#6B7280]'
                                            }`}

                                    >
                                        Password
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div>
                        <div className='mt-7 flex flex-col mx-4'>
                            <div className='w-full'>
                                <label className="text-[16px] leading-[21px] font-semibold text-[#6B7280]">Current Password</label>
                            </div>
                            {/* <div className='w-2/3'>
                                    <input type="password" className="form-control w-full border border-[#D1D5DB] rounded-md p-2 h-11 input" name='currentPassword'

                                        value={userData.currentPassword} placeholder="Enter current password" onChange={handleOnChange} />

                                </div> */}
                            <div className='w-full mt-3 rel'>
                                <div className=' z-10 rel1'
                                >

                                    <input type={!showPass ? "password" : "text"} placeholder='Enter current password' className='h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input ' name='currentPassword'

                                        value={userData.currentPassword}
                                        onChange={handleOnChange}
                                    />

                                    <div className=" mt-[-35px] mx-[315px] h-full flex items-center cursor-pointer text-[#878D98] rel2"  onClick={() => setShowPass(!showPass)}>
                                        {" "}
                                        {!showPass ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}{" "}
                                    </div>


                                </div>
                                {currPassErr && <div className='text-red-500 pt-2'>{currPassErr}</div>}
                            </div>

                        </div>

                    </div>
                    <div className='mt-7 flex flex-col mx-4'>
                        <div className='w-full'>
                            <label className="text-[16px] leading-[21px] font-semibold text-[#6B7280]">New Password</label>
                        </div>
                        {/* <div className='w-2/3'>
                                <input type="password" className="form-control w-full border border-[#D1D5DB] rounded-md p-2 h-11 input" name='newPassword'

                                    value={userData.newPassword} placeholder="Enter new passsword" onChange={handleOnChange} />

                            </div> */}
                        <div className='w-full mt-3 rel'>
                            <div className='relative rel1'>
                                <input type={!showPassNew ? "password" : "text"} placeholder='Enter new password' className='h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input' name='newPassword'
                                    value={userData.newPassword}
                                    onChange={handleOnChange}
                                />
                                <div className="mt-[-35px] mx-[315px] h-full flex items-center cursor-pointer text-[#878D98] rel2" onClick={() => setShowPassNew(!showPassNew)}>
                                    {" "}
                                    {!showPassNew ? (
                                        <VisibilityIcon />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}{" "}
                                </div>
                            </div>
                            {newPassErr && <div className='text-red-500'>{newPassErr}</div>}
                        </div>
                    </div>
                    <div className='mt-7 flex flex-col mx-4'>
                        <div className='w-full'>
                            <label className="text-[16px] leading-[21px] font-semibold text-[#6B7280]">Confirm Password</label>
                        </div>
                        {/* <div className='w-2/3'>
                                <input type="password" className="form-control w-full border border-[#D1D5DB] rounded-md p-2 h-11 input" name='newPassword'

                                    value={userData.newPassword} placeholder="Enter new passsword" onChange={handleOnChange} />

                            </div> */}
                        <div className='w-full mt-3 rel'>
                            <div className='relative rel1'>
                                <input type={!showPassConf ? "password" : "text"} placeholder='Enter confirm password' className='h-11 focus:ring-indigo-600 focus-within:ring-indigo-600 focus-within:border-indigo-600 focus:border-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input' name='confirmPassword'
                                    value={userData.confirmPassword}
                                    onChange={handleOnChange}
                                />
                                <div className="mt-[-35px] mx-[315px] h-full flex items-center cursor-pointer text-[#878D98] rel2" onClick={() => setShowPassConf(!showPassConf)}>
                                    {" "}
                                    {!showPassConf ? (
                                        <VisibilityIcon />
                                    ) : (
                                        <VisibilityOffIcon />
                                    )}{" "}
                                </div>
                            </div>
                            {confPassErr && <div className='text-red-500'>{confPassErr}</div>}
                        </div>
                    </div>
                    <div className='mt-5   flex justify-between  bg-white border-t  m-0 p-3 w-full pr-6'>

                        <div>

                            <button className='hover:bg-[#F9FAFB] flex items-center text-[15px] font-semibold leading-[20px]  p-2 px-3 rounded-md  mx-3 space-x-2 border-[#D1D5DB] border text-[#4B5563]' onClick={resetPasswordValue} type='button' ><span>Reset</span></button>
                        </div>
                        <div className='mr-5'>
                            <button className='bg-[#6366F1] text-white border p-2 px-3 rounded-md mx-3' type='button' onClick={updatePassword}>Update</button>
                        </div>
                    </div>




                </div>
            </div>
        </>
    )
}

export default Password