import { Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './../login/login.scss'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../action/userActions';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from "../../assests/onepoket_logo.png"
import axios from "../../api/api"


const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const [showPass, setShowPass] = useState(false);

   

    const [datas, setData] = useState({
        email: "",
       
    });

    const handleChange = (e) => {
        setData({ ...datas, [e.target.name]: e.target.value });
    }



    /////////////////////////////// authentication ///////////////////////////////
   


    ////////////////-------- Toast -//////////////////////////////
    const toast_fun = (error) => {
        toast.error(`Error: ${error}`, { theme: "colored", autoClose: 2000 });
    }


    ///////////////////////////////// redux /////////////////////////////////////////
    const submitData =async () => {
        try {
            const config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
            setLoading(true);
            const { data } = await axios.post(`/forget-company-user-password`, { email:datas?.email}, config);
            // console.log(data)
            setLoading(false)
            toast.success("For resetting your password, an email has been sent to your registered email address")
            
        } catch (error) {
            // console.log(error);
            // console.log(error, "error")
            toast.error(error.response.data)
            setLoading(false)
        }
    }

    return (
        <>

            {/* //////////////////////////////////////////////////// */}
            <ToastContainer />
            {/* //////////////////////////////////////////////////// */}


            <div className=' main bg-[#F3F4F6] h-[450px] '>
                <div className='Gatekeeper_login rounded bg-white'>
                    <div className='header flex items-center flex-col mt-[30px]'>
                        <img src={Logo} className='w-[50px] h-[50px]' />
                        <h1 className='pt-3'>Forgot Password</h1>
                        <p className='text-[16px] font-normal leading-[21px] text-[#70819C] pt-3 w-5/6 mx-auto'>Please enter your email address to receive a verification code</p>
                    </div>

                    <form onSubmit={handleSubmit(submitData)}>
                        <div className="form-group">
                            <label className='text-[16px] font-semibold text-[#6B7280] leading-[21px] '>Email</label>
                            <input type="email" name="email" className="h-11 focus:ring-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input mt-1" aria-describedby="emailHelp" placeholder="Enter your gatekeeper email" value={datas.email}  {...register("email", { onChange: (e) => { handleChange(e) }, required: "userId is required!" })} />
                            <MailOutlineIcon className='icon1' />
                            <div className='error'>{errors.email?.message}</div>
                        </div>
                        <div className=' w-[100%] mt-10 '>
                            <button
                                className='w-[100%] bg-[#4F46E5] text-white p-5 h-11 rounded-md py-2 text-[14px] font-semibold leading-[21px] hover:bg-[#6366F1]'
                                disabled={loading} // Disable the button while loading is true
                            >
                                <div className="flex items-center justify-center">
                                    {loading ? (
                                        <>
                                            <CircularProgress color="inherit" size={20} style={{ marginRight: '10px' }} /> {/* Show CircularProgress while loading */}
                                            Loading...
                                        </>
                                    ) : (
                                        'Send Email' // Show 'Sign In' text when not loading
                                    )}
                                </div>
                            </button>
                        </div>
                        <div className=" m-6 text-center space-x-1">
                            <span>Back to</span>
                            <Link to='/login'>
                                <span className="hover:underline">Sign in</span>
                            </Link>
                        </div>

                    </form>

                </div>
            </div>
        </>
    )
}

export default ForgotPassword