import React from 'react'
import './product.scss'

const Product = (props) => {
    
    const showProduct = props.showProduct
    const setProduct = props.setProduct
    return (
        <>
            <div className='product' onClick={() => props.fun(props.data)}>
                <img src={props.data.images.image} alt="" />
                <div onClick={()=>setProduct(!showProduct)}> <section> {props.data.product_name} </section>
                    <span> {props.data.product_model} </span>
                </div>
            </div>
        </>
    )
}

export default Product