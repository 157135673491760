import { Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './login.scss'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../action/userActions';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from "../../assests/onepoket_logo.png"


const Login = () => {
    const [loading , setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    const [showPass, setShowPass] = useState(false);

    const toggle = () => {
        setShowPass(!showPass);
    }

    const [data, setData] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    }



    /////////////////////////////// authentication ///////////////////////////////
    const dispatch = useDispatch();
    const token = localStorage.getItem("accessToken");
    const { user } = useSelector(state => state.userReducer);

    useEffect(() => {
        if (token && user) {
            navigate('/');
        }
    }, [user, token]);


    ////////////////-------- Toast -//////////////////////////////
    const toast_fun = (error) => {
        toast.error(`Error: ${error}`, { theme: "colored", autoClose: 2000 });
    }


    ///////////////////////////////// redux /////////////////////////////////////////
    const submitData = () => {
        setLoading(true)
        dispatch(loginUser(data, toast_fun)).then(()=>{
            setLoading(false)
        })
    }

    return (
        <>

            {/* //////////////////////////////////////////////////// */}
            <ToastContainer />
            {/* //////////////////////////////////////////////////// */}


            <div className=' main bg-[#F3F4F6]'>
                <div className='Gatekeeper_login rounded bg-white'>
                    <div className='header flex items-center flex-col mt-[30px]'>
                        <img src={Logo} className='w-[50px] h-[50px]' />
                        <h1 className='pt-3'>Welcome back!</h1>
                        <p className='text-[16px] font-normal leading-[21px] text-[#70819C] pt-2'>Please enter your credentials to sign in!</p>
                    </div>

                    <form onSubmit={handleSubmit(submitData)}>
                        <div className="form-group">
                            <label className='text-[16px] font-semibold text-[#6B7280] leading-[21px] '>User ID</label>
                            <input type="email" name="email" className="h-11 focus:ring-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6 input mt-1" aria-describedby="emailHelp" placeholder="Enter your  email" value={data.email}  {...register("email", { onChange: (e) => { handleChange(e) }, required: "userId is required!" })} />
                            <MailOutlineIcon className='icon1' />
                            <div className='error'>{errors.email?.message}</div>
                        </div>
                        <div className="form-group">
                            <label className='text-[16px] font-semibold text-[#6B7280] leading-[21px] '>Password</label>
                            <input type={showPass ? "text" : "password"} name="password" className="h-11 focus:ring-indigo-600 border-[#D1D5DB] w-[100%] rounded-md border p-6  input mt-1" placeholder="Enter your password" value={data.password} {...register("password", { onChange: (e) => { handleChange(e) }, required: "password is required!" })} />
                            <LockOpenIcon className='icon1' />

                            <span onClick={toggle}>
                                {showPass ? <VisibilityOffIcon className='icon2' /> :
                                    <VisibilityIcon className='icon2' />}
                            </span>

                            <div className='error'>{errors.password?.message}</div>

                        </div>

                        {/* <div className='btnLogin'>
                            <Button className='bt' type='submit' variant='contained'>Login</Button>
                        </div> */}
                        <div className="text-right mt-3 underline text-[#0018FF] hover:text-[#0018FF]">
                            <Link to='/forgot_password'>
                                <h5 className='text-[16px] font-normal leadiing-[24px] text-[#0018FF]'>Forgot password</h5>
                            </Link>
                        </div>

                        <div className=' w-[100%] mt-10 '>
                        <button
                            className='w-[100%] bg-[#4F46E5] text-white p-5 h-11 rounded-md py-2 text-[14px] font-semibold leading-[21px] hover:bg-[#6366F1]'
                            disabled={loading} // Disable the button while loading is true
                        >
                            <div className="flex items-center justify-center">
                                {loading ? (
                                    <>
                                        <CircularProgress color="inherit" size={20} style={{ marginRight: '10px' }} /> {/* Show CircularProgress while loading */}
                                        Loading...
                                    </>
                                ) : (
                                    'Sign In' // Show 'Sign In' text when not loading
                                )}
                            </div>
                        </button>
                        </div>

                    </form>

                </div>
            </div>
        </>
    )
}

export default Login