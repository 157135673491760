import React, { useState, useEffect } from 'react'
import "./packerHome.scss"
import Header from '../../../components/header/Header';
import { Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import QrReader from 'react-qr-reader';
import SideBar from '../../../components/sideBar/SideBar';

import axios from "../../../api/api"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



const PackerHome = () => {



    /////////////////////////////// authentication ///////////////////////////////
    const navigate = useNavigate();
    const token = localStorage.getItem("accessToken");
    const { user } = useSelector(state => state.userReducer);

    useEffect(() => {
        if (!token && !user) {
            navigate('/login');
        }
    }, [user, token, navigate]);

    /////////////////////----------------------------------------//////////////////


    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [scan, setScan] = useState([]);
    const [masterScan, setMasterScan] = useState(null);
    const [prevScan, setPrevScan] = useState("not defined");

    const handleScan = (scanData) => {
        // console.log(scanData, "sacn data")
        if (scanData && scanData != prevScan) {

            setPrevScan(scanData);

            if (!switchQr) {
                //------------------------ Product Qr Scan ---------------//
                setTimeout(async () => {
                    try {
                        const origArray = scanData.split('/');
                        const len = origArray.length;
                        const sort_Id = origArray[len - 1];
                        const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
                        const { data } = await axios.post("/packer_scan", { token: localStorage.getItem("accessToken"), shortId: sort_Id }, config);
                        // console.log(data);
                        setScan(data);
                    } catch (error) {
                        // console.log(error);
                        toast.error(`Error: ${error.response.data}`, { theme: "colored", autoClose: 2000 });
                        setPrevScan("not defined");
                    }
                }, 100);

            } else if (!masterScan) {

                //------------------------ Master Qr Scan ---------------//
                setTimeout(async () => {
                    try {
                        const origArray = scanData.split('/');
                        const len = origArray.length;
                        const sort_Id = origArray[len - 1];
                        const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
                        const { data } = await axios.post("/scan_masterQR", { token: localStorage.getItem("accessToken"), shortId : sort_Id }, config);
                        // console.log(data);
                        setMasterScan(data);
                    } catch (error) {
                        // console.log(error);
                        toast.error(`Error: ${error.response.data}`, { theme: "colored", autoClose: 2000 });
                        setPrevScan("not defined");
                    }
                }, 100);

            }
        }
    };

    const handleError = (err) => {
        console.error(err);
    };



    //////////////////--------------- switch Qr ------------------///////////////////////////
    const [switchQr, setSwitchQr] = useState(false);



    ///////////////////////////---------Side Bar --------///////////////////////////
    const openSideBar = () => {
        document.getElementById("sideBar").style.left = "0px";
    }


    ////////////////-------------------- get Buffer ----------------/////////////////////////

    useEffect(() => {
        getBuffer();
    }, []);

    const getBuffer = async () => {
        try {
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/packer_fetch", { token: localStorage.getItem("accessToken") }, config);
            setScan(data);
        } catch (error) {
            // console.log(error);
        }
    }


    /////////////////////------------- fetch Master qr -------------------/////////////////////////

    useEffect(() => {
        fetchMasterQr();
    }, []);

    const fetchMasterQr = async () => {
        try {
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/get_current_masterQr", { token: localStorage.getItem("accessToken") }, config);
            setMasterScan(data.serial_number);
        } catch (error) {
            // console.log(error);
        }
    }


    ////////////////------------------- clear buffer -----------------------//////////////////////

    const clearBuffer = async () => {
        try {
            setScan([]);
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/clear_buffer", { token: localStorage.getItem("accessToken") }, config);
            toast.success(`SUCCESS: ${data}`, { theme: "dark", autoClose: 2000 });
        } catch (error) {
            // console.log(error);
        }
    }


    ///////////////--------------------- clear Master -----------------////////////////////
    const clearMaster = async () => {
        try {
            setMasterScan(null);
            setPrevScan("not defined");
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/remove_masterQR", { token: localStorage.getItem("accessToken") }, config);
            toast.success(`SUCCESS: ${data}`, { theme: "colored", autoClose: 2000 });
        } catch (error) {
            // console.log(error);
        }
    }


    /////////////////---------------------- link Qr -----------------------//////////////////
    const linkQr = async () => {
        try {
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/packer_link_QR", { token: localStorage.getItem("accessToken") }, config);
            toast.success(`SUCCESS: ${data}`, { theme: "colored", autoClose: 2000 });
            setScan([]);
            setMasterScan(null);
            setSwitchQr(false);
        } catch (error) {
            // console.log(error);
        }
    }


    //////////////////-------------- remove qr --------------//////////////////////

    const del_Qr = async (val) => {
        try {
            // console.log(val.id);
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/remove_QR", { token: localStorage.getItem("accessToken"), id: val.id }, config);
            setScan(data);
        } catch (error) {
            // console.log(error);
        }
    }





    return (
        <>


            {/* //////////////////////////////////////////////////// */}
            <ToastContainer />
            {/* //////////////////////////////////////////////////// */}



            <div className='main'>


                <div className='packerHome'>
                    <Header fun={openSideBar} clearBuffer={clearBuffer} />
                    <SideBar />

                    <div className='qrCon'>
                        <QrReader
                            facingMode={"environment"}
                            delay={500}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: "200px", heigth: "200px" }}
                        />
                    </div>

                    {switchQr && !masterScan && <div className='masterText1'>Please scan master Qr</div>}
                    {switchQr && masterScan && <div className='masterText2'>Master QR Scanned :</div>}

                    {switchQr && masterScan && <div className='master_serial'> {masterScan}  <span onClick={clearMaster}><ClearIcon className='clearIcon' /></span> </div>}

                    <section className='count'> {scan.length} QR scanned:</section>

                    <div className='scannedQr'>
                        {scan.map((val, index) => {
                            return (
                                <div key={index} className='qrNumber'> {val.serial_number}  <span onClick={() => del_Qr(val)}><ClearIcon className='clearBt' /></span> </div>
                            )
                        })}
                    </div>

                    {!switchQr && <div className='masterBtCon'><Button className='masterBtn' variant='contained' onClick={() => setSwitchQr(true)}>Scan Master QR</Button></div>}
                    {switchQr && <div className='linkGoCon'>
                        <div>
                            <Button className='go' variant='contained' onClick={() => setSwitchQr(false)}>Go Back</Button>
                            <Button className='link' variant='contained' onClick={linkQr}>Link</Button>
                        </div>
                    </div>}


                </div>

            </div>
        </>
    )
}

export default PackerHome