import React, { useEffect, useState } from 'react'
import "./gateHome.scss"
import Header from '../../../components/header/Header'
import { Button } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import QrReader from 'react-qr-reader';
import SideBar from '../../../components/sideBar/SideBar';

import axios from "../../../api/api"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const GateHome = () => {


    /////////////////////////////// authentication ///////////////////////////////
    const navigate = useNavigate();
    const token = localStorage.getItem("accessToken");
    const { user } = useSelector(state => state.userReducer);

    useEffect(() => {
        if (!token && !user) {
            navigate('/login');
        }
    }, [user, token, navigate]);

    /////////////////////----------------------------------------//////////////////



    const baseUrl = process.env.REACT_APP_BASE_URL;

    const [scan, setScan] = useState([]);
    const [prevScan, setPrevScan] = useState("not defined");

    const handleScan = (scanData) => {

        if (scanData && scanData != prevScan) {

            setPrevScan(scanData);

            setTimeout(async () => {
                try {
                    const origArray = scanData.split('/');
                    const len = origArray.length;
                    const sort_Id = origArray[len - 1];
                    const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
                    const { data } = await axios.post("/gateKeeper_scan", { token: localStorage.getItem("accessToken"), shortId : sort_Id }, config);
                    setScan(data);
                } catch (error) {
                    // console.log(error);
                    toast.error(`Error: ${error.response.data}`, { theme: "colored", autoClose: 2000 });
                    setPrevScan("not defined");
                }
            }, 100);

        }
    };

    const handleError = (err) => {
        console.error(err);
    };





    ////////////////-------------------- get Buffer ----------------/////////////////////////

    useEffect(() => {
        getBuffer();
    }, []);

    const getBuffer = async () => {
        try {
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/gateKeeper_fetch_buffer", { token: localStorage.getItem("accessToken") }, config);
            setScan(data);
        } catch (error) {
            // console.log(error);
        }
    }


    ////////////////------------------- clear buffer -----------------------//////////////////////

    const clearBuffer = async () => {
        try {
            setScan([]);
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/gateKeeper_clear_buffer", { token: localStorage.getItem("accessToken") }, config);
            toast.success(`SUCCESS: ${data}`, { theme: "dark", autoClose: 2000 });
        } catch (error) {
            // console.log(error);
        }
    }



    //////////////////-------------- remove qr --------------//////////////////////

    const del_Qr = async (val) => {
        try {
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/gateKeeper_delete_buffer", { token: localStorage.getItem("accessToken"), id: val.id }, config);
            setScan(data);
        } catch (error) {
            // console.log(error);
        }
    }




    //////////////----------------- dispatch form --------------/////////////////////////

    const [shippedForm, setShippedForm] = useState({
        token: localStorage.getItem("accessToken"),
        shipped_to: "",
        bill_number: "",
        vehicle_number: ""
    });

    const handleChange = (e) => {
        setShippedForm({ ...shippedForm, [e.target.name]: e.target.value });
    }


    const submitData = async () => {
        try {

            if (shippedForm.bill_number && shippedForm.shipped_to && shippedForm.vehicle_number) {

                const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
                const { data } = await axios.post("/gateKeeper_dispatch", shippedForm, config);
                toast.success(`SUCCESS: ${data}`, { theme: "dark", autoClose: 2000 });

                setShippedForm({
                    token: localStorage.getItem("accessToken"),
                    shipped_to: "",
                    bill_number: "",
                    vehicle_number: ""
                });

                setScan([]);

            } else {
                toast.warn(`Warning: All fields required!`, { theme: "dark", autoClose: 2000 });
            }

        } catch (error) {
            // console.log(error);
        }
    }




    ///////////////////////////---------Side Bar --------///////////////////////////

    const openSideBar = () => {
        document.getElementById("sideBar").style.left = "0px";
    }





    return (
        <>


            {/* //////////////////////////////////////////////////// */}
            <ToastContainer />
            {/* //////////////////////////////////////////////////// */}


            <div className='main'>

                <div className='gateHome'>
                    <Header fun={openSideBar} clearBuffer={clearBuffer} />
                    <SideBar />

                    <div className='qrCon'>
                        <QrReader
                            facingMode={"environment"}
                            delay={500}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: "200px", heigth: "200px" }}
                        />
                    </div>

                    <section className='count'> {scan.length} QR scanned:</section>

                    <div className='scannedQr'>
                        {scan.map((val, index) => {
                            return (
                                <div key={index} className='qrNumber'> {val.serial_number} <span onClick={() => del_Qr(val)}><ClearIcon className='clearBt' /></span> </div>
                            )
                        })}
                    </div>

                    <div className='dispatchForm'>

                        <div className="form-group">
                            <input type="text" className="form-control" name='shipped_to' value={shippedForm.shipped_to} placeholder="Shipped to" onChange={handleChange} />
                        </div>

                        <div className="form-group">
                            <input type="text" className="form-control" name='bill_number' value={shippedForm.bill_number} placeholder="Bill Number" onChange={handleChange} />
                        </div>

                        <div className="form-group">
                            <input type="text" className="form-control" name='vehicle_number' value={shippedForm.vehicle_number} placeholder="Vehicle Number" onChange={handleChange} />
                        </div>

                        <div className='btnCon'>
                            <Button className='dispatchBt' variant='outlined' onClick={submitData}>Dispatch</Button>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default GateHome