import axios from "../api/api";
import { REQUEST_PRODUCT, LOAD_PRODUCT, SUCESS_PRODUCT, } from "../constants/productConstants";

export const getProducts = (id) => async (dispatch) => {
    try {
        const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
        const { data } = await axios.post("/factory_product", { factory_id: id }, config);
        dispatch({ type: SUCESS_PRODUCT, payload: data });
    } catch (error) {
        // console.log(error);
    }
}

