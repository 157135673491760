import { SUCCESS_QR_HISTORY } from "../constants/qrConstants";

const qrHistoryReducer = (state = { qrData: null }, action) => {
    switch (action.type) {
        case SUCCESS_QR_HISTORY:
            return {
                ...state,
                qrData: action.payload
            }

        default: return state;
    }
}

export default qrHistoryReducer;