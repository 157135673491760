import { SUCCESS_QR, REQUEST_QR, RESET_QR } from "../constants/qrConstants";
import axios from "../api/api";
import { toast } from "react-toastify";

export const getQrUrl = (qrData, qr_type) => async (dispatch) => {
    try {
        dispatch({ type: REQUEST_QR });
        const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };


        if (qr_type == "product_qr") {
            const token = localStorage.getItem("accessToken")
            qrData.token = token


            const { data } = await axios.post("/print_QR", qrData, config);
            // console.log(data);
         
            dispatch({ type: SUCCESS_QR, payload: data });
         


        }

        if (qr_type == "master_qr") {
            // console.log({ ...qrData, token: localStorage.getItem("accessToken") });
            const { data } = await axios.post("/print_masterQR", { ...qrData, token: localStorage.getItem("accessToken") }, config);
            // console.log(data);
            dispatch({ type: SUCCESS_QR, payload: data });
        }

    } catch (error) {
        // console.log(error, "error");
        return Promise.reject(error.response.data);
    }
}

export const resetQrUrl = () => async (dispatch) => {
    try {
        dispatch({ type: RESET_QR });
    } catch (error) {
        // console.log(error);
    }
}