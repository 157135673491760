import { REQUEST_USER, LOAD_USER, SUCESS_USER, LOGOUT_USER, HISTORY_SUCCESS, HISTORY_FAIL } from "../constants/userConstants";
import axios from "../api/api";


export const loginUser = (userData, toast_fun) => async (dispatch) => {
    try {
        const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
        const { data } = await axios.post("/company_user_login", userData, config);
        localStorage.setItem("accessToken", data.accessToken);
        dispatch({ type: SUCESS_USER, payload: data.user });
    } catch (error) {
        toast_fun(error.response.data.message);
        // console.log(error);
    }
}


export const loadUser = (navigate) => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        if (token) {
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/company_user_identity", { token: token }, config);
            dispatch({ type: LOAD_USER, payload: data });
        } else {
            // console.log("token is not present...!");
        }
    } catch (error) {
        // console.log("token is not present...!");
        localStorage.removeItem("accessToken");
        navigate("/login");
    }
}


export const userHistory = (product_id) => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        if (token) {
            const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
            const { data } = await axios.post("/factory_operator_history", { token: token  , product_id  : product_id }, config);
            dispatch({ type: HISTORY_SUCCESS, payload: data });
            return data
        }
    } catch (error) {
        // console.log(error);
        // if(error.response.data){
        //     dispatch({type : HISTORY_FAIL})
        // }
        // dispatch({type : HISTORY_FAIL})
        return Promise.reject(error.response.data)
    }
}


export const logoutUser = () => async (dispatch) => {
    try {
        // const token = localStorage.getItem("accessToken");
        // const config = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };
        // const { data } = await axios.post("/factory_operator_logout", { token: token }, config);
        localStorage.removeItem("accessToken");
        dispatch({ type: LOGOUT_USER });
        dispatch({type  :HISTORY_FAIL})
    } catch (error) {
        // console.log(error);
    }
}

