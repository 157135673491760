import React from 'react'

const TemplateCard = (props) => {

    const setTemplate = props.setShowTemplate
    const template = props.showTemplate

    return (
        <div className='' onClick={() => props.templates(props.data)} style={{display : "flex" , justifyContent: "space-between" , padding : "5px"}}>
            {/* <img src={props.data.images.image} alt="" /> */}
            <div onClick={()=>setTemplate(!template)}> <section style={{cursor:"pointer"}}> {props.data.template_name} </section>
                {/* <span> {props.data.product_model} </span> */}
            </div>
            <div>
                <div><a href={props.data.pdf_url} target="_blank" rel="noopener noreferrer">View PDF</a></div>
            </div>
        </div>
    )
}

export default TemplateCard
