import { REQUEST_PRODUCT, LOAD_PRODUCT, SUCESS_PRODUCT, } from "../constants/productConstants";

const productReducer = (state = { products: null }, action) => {
    switch (action.type) {
        case REQUEST_PRODUCT:
        case LOAD_PRODUCT:
            return {
                ...state,
                products: null
            }

        case SUCESS_PRODUCT:
            return {
                ...state,
                products: action.payload
            }

        default:
            return state;
    }
}

export default productReducer;